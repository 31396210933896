.modal{
    button{
        position:fixed;
        right:30px;
        top:30px;
        align-items: center;
        justify-content: center;
        display: flex;
        line-height: 1em;
        aspect-ratio: 1;

    }
}

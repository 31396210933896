.apexchart-total-radialbar{
    position: relative;
    svg{
        transform:scale(1.2);
    }
    figcaption{
        position: absolute;
        display:flex;
        flex-direction: column;
        align-items:center;
        justify-content:center;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);

        .badge{
            font-size:rem-calc(18);
            &::first-letter{
                text-transform: uppercase;
            }
        }
        small{
            font-size:rem-calc(12);
            font-weight:500;
        }
    }
}

.total-radialbar-label{
    font-weight:bold;
    text-transform: uppercase;
    font-size:rem-calc(12);
}

.total-radialbar-value{
    font-size:rem-calc(64);
    font-weight:bold;
    line-height:20px;
    padding-bottom:0;
    span{
        line-height:1em;
    }

}

.total-radialbar-total{

    margin-bottom:10px;
}

.select-shadow{

    background:linear-gradient(to bottom, white 0, white 50%, black 50%);
    padding: 1px 1px 1px 0;
    border-radius: 0 30px 30px 0;
    select{
        padding-block:$input-btn-padding-y - 1;
        border:0;
        border-radius: 0 30px 30px 0;
    }
}

.chart-headline{
    width:100%;
    display: grid;
    grid-template-columns:9.5fr 1fr 1.2fr;
    align-items:baseline;


    h2{
        display:flex;
        align-items:baseline;
        gap:20px;
        small{
            font-weight:600;
        }
    }
    h4{
        margin:0;
    }
    .col-max{
        text-align:right;
        padding-right:12px;
    }

}

.chart-table{
    border-radius:30px;
    overflow: hidden;
}

.chart-table-row{
    display:grid;
    width:100%;
    grid-template-columns:7fr 1fr 1fr 1.2fr 1.3fr;
    justify-items:stretch;
    //align-content:center;

    > div{
        align-items:center;
        display:flex;
        padding-inline:20px 25px;
        &:first-child{
            padding-left:30px;
        }
        &.col-inspect, &.col-fulfilled, &.col-max{
            justify-content:flex-end;
        }

        &.col-gold{
            font-weight:bold;
            &:not(.justify-content-between){
                justify-content:flex-end;
            }
        }
    }
}

.chart-table-line{
    grid-template-rows:60px;

    &:nth-of-type(odd){
        background:$grey-100;
        > div{
            &.col-max{
                background:$grey-200;
            }
            &.col-gold{
                &:nth-of-type(even){
                    background:$gold-200;
                }
                &:nth-of-type(odd){
                    background:$gold-100;
                }
            }
        }

    }
    &:nth-of-type(even){
        background:$grey-200;
        > div{

            &.col-max{
                background:$grey-300;
            }
            &.col-gold{
                &:nth-of-type(even){
                    background:$gold-250;
                }
                &:nth-of-type(odd){
                    background:$gold-200;
                }
            }
        }

    }

}

.chart-table-headline{
    grid-template-rows:auto;
    //min-height:70px; das darf nicht sein, wofür haben wir das gebraucht?
    > div{
        background:$grey-800;
        color:$white;
        padding-block:10px;
        &.col-max{
            background:$grey-900;
        }
        &.col-gold{
            color:$black;
            font-weight:bold;
            &:not(.justify-content-between){
                align-content: flex-end;
            }
            &:nth-of-type(odd){
                background:$gold;
            }
            &:nth-of-type(even){
                background:$gold-600;
            }


        }
    }
}

.chart-table-subheadline{
    font-size:rem-calc(12);
    grid-template-rows:40px;
    text-transform:uppercase;
    > div{
        background:$grey-400;
        &.col-max{
            background:$grey-500;
            padding-left:0;

        }
        &.col-gold{
            font-weight:400;
            //adding: 0;
            padding-left:0;

            &:nth-of-type(odd) {
                background: $gold-300;
            }
            &:nth-of-type(even) {
                background: $gold-400;
            }

        }
    }
}

.chart-clusternumber {
    display: inline-flex;
    background: $grey-300;
    border-radius: 15px 15px 0 15px;
    aspect-ratio: 1;
    width: 50px;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.chart-table--grouped{
    .chart-table-row{
        grid-template-columns: 4.5fr 0.9fr 1.1fr 1.1fr 1.1fr 1.1fr;
    }


    .chart-table-darkheadline{
        grid-template-rows:60px;
        background:$black-600;
        color:white;
        font-weight:bold;

        grid-template-columns: 5.4fr 1.1fr 1.1fr 1.1fr 1.1fr;
        td:nth-of-type(even){
            background:$black-700;
        }
    }
}

.chart--foldable{
    .chart-table-headline{
        > div:first-child{
            position:relative;
            &:before{
                content:url("../img/icons/pfeil-runter.svg");
                position:absolute;
                left:10px;
                transition:transform 0.2s linear 0s;
                transform-origin:center center;
            }

        }
    }
    &[aria-expanded=false]{
        .chart-table-headline {
            div:first-child {
                &:before {
                    transform:rotate(-90deg);
                }
            }
        }
    }
}
/**
 * Table Bar
 */

.chart-table--bar{

    .chart-table-headline{
        grid-template-rows:40px;
        > div{
            background:transparent;
            text-transform:uppercase;
            font-size:rem-calc(12);
            color:$dark;
            &.justify-content-end{
                padding-left:0;
            }
        }
    }
    .chart-table-row{
        grid-template-columns:9fr 1fr 1.2fr;
    }
    .chart-bar{
        border-radius:30px;
        overflow:hidden;

        .chart-table-line{
            grid-template-rows:50px;
            position:relative;
            --before-width:0;
            --before-delay:0;
            border-radius:0 25px 25px 0;
            &:first-of-type{
                background:$gold-100;
                &:before{
                    background-color:$gold;
                }
            }

            &:nth-of-type(2){
                background:none;
                &:before{
                    background-color:$blue-100;
                }
            }
            &:nth-of-type(3){
                background:$blue-50;
                &:before{
                    background-color:$blue-500;
                }
            }
            &:nth-of-type(4){
                background:none;
                &:before{
                    background-color:$blue-700;
                }
            }
            &:nth-of-type(5){
                background:none;
                &:before{
                    background-color:$blue-500;
                }
            }
            &:nth-of-type(6){
                background:none;
                &:before{
                    background-color:$blue-100;
                }
            }
            > div{
               position:relative;
                //padding-inline:25px;
                z-index:2;

            }
            &:before{
                position:absolute;
                left:0;
                top:0;
                bottom:0;
                width:var(--before-width);
                border-radius: 0 25px 25px 0;
                content:"";
                z-index:1;
                transition:width 0.75s ease-out var(--before-delay);
            }

        }
    }
}
.chart-table-pillar{
    .chart-table-row {
        grid-template-columns:10fr 1.2fr 1.3fr;
        &:nth-of-type(even){
            > div{
                &:nth-of-type(odd){
                    background:$grey-100;
                }
                &:nth-of-type(even){
                    background:$grey-200;
                }
                &.col-gold{
                    background:$gold-200;
                }
            }

        }
        &:nth-of-type(odd){

            > div{
                &:nth-of-type(odd){
                    background:$grey-200;
                }
                &:nth-of-type(even){
                    background:$grey-300;
                }
                &.col-gold{
                    background:$gold-300;
                }
            }

        }
    }
}
.chart-table--comparison-pillar{


    @for $i from 0 through 5 {
        &.chart-table-vga-#{$i} {
            .chart-table-row {
                grid-template-columns: #{7.5 - $i*0.5}fr repeat($i + 1, 1fr) 1.2fr;
                @if $i > 4 {
                    > div{
                        padding-inline:18px;
                    }
                }
            }
        }
    }
    .chart-table-row{
        &:nth-of-type(even){
            > div{
                &:nth-of-type(odd){
                    background:$grey-100;
                }
                &:nth-of-type(even){
                    background:$grey-200;
                }
                &.col-gold{
                    background:$gold-200;
                }
            }

        }
        &:nth-of-type(odd){

            > div{
                &:nth-of-type(odd){
                    background:$grey-200;
                }
                &:nth-of-type(even){
                    background:$grey-300;
                }
                &.col-gold{
                    background:$gold-300;
                }
            }

        }
    }


    .chart-table-row.chart-table-subheadline{
        > div{
            &:nth-of-type(odd){
                background:$grey-400;
            }
            &:nth-of-type(even){
                background:$grey-500;
            }
            &.col-gold{
                background:$gold-300;
            }
        }
    }

}

.chart-table-legend{
    display:flex;
    flex-wrap:wrap;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    counter-reset: letter-counter;
    list-style-type: none;
    padding:0;
    gap:10px;
    li{
        display:inline-flex;
        flex:1 1 auto;
        counter-increment: letter-counter;
        align-items:center;
        &:nth-of-type(1){
            &:before{
                background:$blue-100;
            }
        }
        &:nth-of-type(2){
            &:before{
                background:$blue-500;
            }
        }
        &:nth-of-type(3){
            &:before{
                background:$blue-700;
            }
        }
        &:nth-of-type(4){
            &:before{
                background:$blue-500;
            }
        }
        &:nth-of-type(5){
            &:before{
                background:$blue-100;
            }
        }
        &:last-of-type{
            &:before{
                content:"";
                background:$gold !important;
            }
        }

    }

    li::before{
        display:inline-flex;
        width:22px;
        height:22px;
        align-items:center;
        justify-content:center;
        border-radius:11px;
        background:blue;
        color: $dark;
        font-size:rem-calc(12);
        font-weight:bold;
        content:counter(letter-counter, upper-alpha);
        margin-right:5px;

    }
}

.data-max{
    position:relative;
    &:before{
        content:"";
        position:absolute;
        bottom:10px;
        left:10px;
        right:10px;
        height:5px;
        border-radius:3px;
        background:$blue-200;

    }
    &:nth-of-type(2){
        &:before{
            background:$blue-100;
        }
    }
    &:nth-of-type(3){
        &:before{
            background:$blue-500;
        }
    }
    &:nth-of-type(4){
        &:before{
            background:$blue-700;
        }
    }
    &:nth-of-type(5){
        &:before{
            background:$blue-500;
        }
    }
    &:nth-of-type(6){
        &:before{
            background:$blue-100;
        }
    }
    &.col-gold{
        &:before{
            background:$gold;
        }
    }
}

/**
wenns im Offcanvas drinnen ist
 */
.offcanvas{
    .chart-table-row{
        grid-template-columns:3.2fr 0.9fr 0.9fr 1.2fr 1.3fr;
        .btn{
            padding-inline:10px;
        }
    }
    .chart-headline{
        grid-template-columns:5fr 1.2fr 1.3fr;

    }
    .chart-headline{
        margin-top:0;
    }

    .chart-table--grouped{
        .chart-table-line{
            min-height:60px;
            grid-template-rows:auto;
        }
        .chart-table-row{
            grid-template-columns: auto 90px  repeat(4,100px);
            :is(.col-max,.col-gold){
                font-size:0.75em;
                padding-inline:15px;
                svg{
                    transform:scale(0.75);
                }

            }

        }
        .d-flex{
            word-break:break-word;
            span{
                line-height:1em;
            }
            gap:10px;
            > svg{
                flex: 0 0 23px;

            }
        }
    }
}

.navbar-nav {
    a{
        font-weight:600;
        &.active{
            font-weight:600;
            border-bottom:1px solid $black-800;
        }
    }
}

.dropdown-divider{
    margin-inline:rem-calc(20);
}

html{
    scroll-padding-top:5vh;
    scrollbar-gutter:stable;
    overflow-y: scroll;
}

#link-to-top{
    position:fixed;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:15px 15px 0 15px;
    bottom:10px;
    right:10px;
    background:$grey-200;
    width:50px;
    height:50px;
    z-index:-1;
    opacity:0;
    transition:opacity 0.2s linear 0s;
    border:0;
    &.visible{
        opacity:1;
        z-index:500;
    }

}

#header{
    height:77px;
}

main{
    margin-top:77px;
}

button{
    &[data-favourite="0"] {
        .star-filled{
            visibility:hidden;
        }
    }
    &[data-favourite="1"] {
        .star-filled{
            visibility:visible;
        }
    }
}

.siegel{
    @extend .badge;
    @extend .rounded-pill;
    @extend .w-100;
    @extend .text-black;
    padding-block:11px;
}


.nav{
    .btn{
        background:#F4F4F4;
        &.active{
            background:$primary;
            border-color:transparent;
        }
    }
}

.download-list{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:30px;
    align-items:stretch;
    .offcanvas & {
        grid-template-columns: 1fr;
    }
}

.card-download{
    border:0;
    position:relative;
    border-radius:30px 30px 0 30px;
    padding:30px 35px;
    background:$grey-400;
    transition:all 0.2s linear 0s;
    &:hover{
        background:$gold;
    }
    a{
        color:$black;
        padding-right:50px;

    }

    svg{
        position:absolute;
        top:30px;
        right:30px;

    }
}

.news-swiper{
    width:550px;
    overflow:hidden;
    .card{
        background:$gold-150;
        width:380px;

    }
    .swiper-slide{
        opacity:0.2;
        &.swiper-slide-active{
            opacity:1;
        }
        &.swiper-slide-next,&.swiper-slide-prev{
            opacity:0.5;
        }
    }

}

.offcanvas{
    --offcanvas-transition: all 0.3s ease-in-out;
    padding:50px 50px 0;
   // transition:width 0.5s ease-in-out 0s;

    &.offcanvas-scrollable{
        &:after{
            position:absolute;
            bottom:0;
            left:0;
            right:0;
            height:30px;
            background-image:linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
            content:url("../img/icons/scroll-down.svg");
            display: flex;
            align-items: flex-end;
            justify-content: center;

        }
    }

}
ul.offcanvas-navigation{
    padding:0;
    margin:0;
    list-style:none;
    li{
        display:flex;
        gap:10px;
        line-height:em-calc(24,18);
        padding-block:5px;

        &:before{
            flex:0 0  auto;
            content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEzIDEwIj4KICAgIDxwYXRoIGQ9Ik0xMi42NzUsNS4zOSw4LjQ1Miw5LjYyNWEuNjc3LjY3NywwLDAsMS0uOTU5LS45NTdsMy4wNy0zLjA3OUguNjc4YS42NzguNjc4LDAsMCwxLDAtMS4zNTVoOS44ODVMNy40OTIsMS4xNTZBLjY3Ny42NzcsMCwxLDEsOC40NTIuMmw0LjIyMyw0LjIzNGEuNjc3LjY3NywwLDAsMSwwLC45NTciIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiIGZpbGw9IiMxZjE2MzMiLz4KPC9zdmc+Cg==")
        }
        a{
            color:$black;
        }
    }
}
.offcanvas-body{
    overflow-y:auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    //padding-right:86px;
    padding-block:40px;
    padding-inline:0;
    position:relative;
    &::-webkit-scrollbar {
        display: none;
    }


}

.offcanvas-title{
    margin-bottom:rem-calc(15);
}

.offcanvas-navigation-fixed{
    position:fixed;
    top:22px;
    right:0;
    display:flex;
    flex-direction: column;
}

.offcanvas-wide{
    --bs-offcanvas-width: 80vw !important;
}


.charts-container{
    display:flex;
    align-items:center;
    margin:auto;
    gap:30px;
    figure{
        text-align:center;
    }
}

.warbanner{
    background-color:$gold;
    background-image:url(../img/icons/bg-klammer.svg);
    background-repeat: no-repeat;
    background-size:contain;
    background-position:bottom right;
    aspect-ratio:1400/286;
    border-radius:30px;
    //padding-inline:50px;
    max-width:1400px;
    display: grid;
    grid-template-columns:1fr;
    grid-template-rows: 1fr 3fr 1fr;
    margin-bottom:80px;
    margin-inline:auto;
    z-index:100;
    @include media-breakpoint-down(xxl){
        margin-inline:15px;
    }
    h1 + p{
        font-size:rem-calc(24);
        font-weight:500;
    }
    p{
        margin:0;
    }
    &.has-navigation{
        justify-content:flex-end;
    }

    &.sticky{
        background:transparent;
        padding-inline:0;
        .warbanner-content{
            opacity:0;
        }
        .warbanner-sticky{
            transition:border-radius 0.2s linear 0s;
            background:$gold;
            border-radius:30px;
            padding-inline:50px;
            margin-inline:auto;
            position:fixed;
            //width:100%;
            @include media-breakpoint-down(xxl){
                margin-inline:15px;
            }
            left:0;
            right:0;
            z-index:1000;
        }
        .d-sticky{
            opacity:1;
        }
    }

}
.warbanner-content{
    grid-row-start: 2;
    transition:opacity 0.2s linear 0s;
    align-content:center;
    padding-inline:50px;
}
.warbanner-sticky{
    grid-row-start: 3;
    border-radius:0 0 30px 30px;
    padding-inline:50px;
    max-width:1400px;

    h4{
        margin:0;
    }
    .sticky & {
        width:auto;
    }

}
.d-sticky{
    transition:all 0.2s linear 0s;
   opacity:0;
    display:flex;
    align-items:center;
    height:72px;
}
.warbanner-split{
    display:grid;
    margin-inline:12px;
    grid-template-columns: 1fr auto;
    align-items:stretch;
    max-width:1400px;
    margin:auto;
    @include media-breakpoint-down(xxl){
        margin-inline:15px;
    }
    gap:30px;
    .warbanner{
        display:flex;
        aspect-ratio:auto;
        margin:0;
        padding-inline:50px;
        background-position:bottom left;
        background-size:cover;
    }
}

.warbanner-dark{
    aspect-ratio:1;
    border-radius:30px;
    padding:30px;
    background:$black;
    color:white;
}

.warbanner-card{
    min-width:235px;
    background-color:rgba(white, 0.7);
    display:grid;
    border-radius:15px;
    padding:15px 20px;
    grid-template-columns: auto auto;
    grid-template-rows:64px auto;
    align-items:center;
    p{
        margin:0;
    }
    p:nth-of-type(2){
        font-size:rem-calc(64);
        font-weight:bold;
        text-align:end;
    }
    p:nth-of-type(3){
        grid-column:2;
        text-align:end;
    }
}

.frm-search{
    :is(button,input){
        border:none;
        background-color:$grey-200;
    }
}

.form-text{
    padding-left:78px;
    padding-right:30px;
}


.input-group{
    .input-group-text{
        background:$grey-400;
        border:none;
        padding-inline:0;
        width:60px;
        border-radius:15px 0 0  15px;
        align-items:center;
        justify-content:center;
        position: relative;
        span{
            position:absolute;
            top:18px;
            right:12px;
            font-size:10px;
            font-weight:bold;
        }
    }
    .form-control{
        background-color:$grey-200;
        &:not(.is-invalid){
            border:none;
        }

    }
    .form-floating{
        label{
            text-transform:uppercase;
            font-size:12px;
            &:after {
                background-color: transparent !important;
            }
        }
    }
    input:required:invalid{
        border:1px solid $red;
    }
}



#mobile-app{

    background-color:$black;
    background-image:url(../img/icons/bg-klammer.svg);
    background-repeat:no-repeat;
    background-position: right bottom;
    aspect-ratio:1;
    color:$light;
    display:none !important;
    justify-content:center;
    margin:80px 30px;
    border-radius:15px;
    font-size:30px;
    font-weight:600;
    padding:30px;
    flex-direction: column;
    gap:20px;
    svg{
        width:117px;
    }
}

@media (max-width:1024px){
    .layout-portal{
        .navbar-toggler{
            display:none !important;
        }
        #mainmenu{
            display:none !important;
        }
        main{
            display:none !important;
        }
        #mobile-app{
            display:flex !important;
        }
    }

}


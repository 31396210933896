.apexchart-radialbar{
    display:flex;
    flex-direction: column;
    align-items:center;
    width:160px;
    margin:auto;
    min-height:170px;
    svg{
        transform:scale(1.2);
    }
    div[data-radialbar]{
        width:160px;
        aspect-ratio:1;
    }
}
.radialbar-info{
    position: absolute;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    top:48%;
    left:50%;
    transform:translate(-50%, -50%);

    small{
        font-size:rem-calc(12);
        font-weight:500;
    }
}


.radialbar-value{
    color:var(--valuecolor);
    font-size:rem-calc(24);
    font-weight:bold;
    line-height:1em;

}

.radialbar-total{
    white-space:nowrap;
    line-height:1em;
}

.radialbar-label{
    text-align:center;
    line-height:em-calc(16,14);
}

$black: #1D1C1F;
$white: #FFFEFF;

$dark :$black;

$gold:   #DBB659;
$silver: #989695;
$bronze: #AA7153;

$gold-60:#F0DBA9;
$silver-60: #c1c0bf;
$silver-40: #d6d5d5;

$red:    #93304E;
$red-80: #A65870;
$red-60: #BE8395;

$rose:   #EF767A;
$yellow: #FFBD1F;

$gold-100: #F8F3E2;
$gold-150: #F8F0DE;
$gold-200: #F5EDD6;
$gold-250: #F2E8CD;
$gold-300: #F2DFAE;
$gold-400: #F0DBA9;
$gold-600: #D3B05B;
$black-600:#676462;
$black-700:#646160;
$black-800:#1F1633;

$blue-50:  #EBF5F4;
$blue-100: #A9D8D2;
$blue-200: $blue-100; //Hack
$blue-300: #8BC3D3;
$blue-500: #69B9B0;
$blue-700: #2A9D8F;
$blue-900: #1F1633;


$green: #2A9D8F;
$green-80: #54B0A5;
$green-60: #7FC4BB;
$green-40: #A9D7D2;
$green-20: #D4EBE8;

$blue:#8BC3D3;

$grey-100: #FAFAFA;
$grey-200: #F4F4F4;
$grey-300: #F2F0EF;
$grey-400: #E6E6E6;
$grey-500: #E0DFDF;
$grey-800: #9D9B9B;
$grey-900: #989695;

$grey-off: #eeeeee;

$groupgrey:#8c8990;
$columngrey:#ededed;

$fulfilled:$gold;
$not-fulfilled:#abafba;


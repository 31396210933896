.table-ranking{

    table-layout:fixed;
    border-collapse: collapse;
    border-radius:30px;
    border-spacing: 0;
    overflow: hidden;
    &.table-auto{
        table-layout:auto;
    }
    caption{
        font-weight:700;
        background:$grey-800;
        color:white;
       // border-radius:30px 30px 0 0;
        padding:20px 30px;
    }

    thead{
        th{
            padding-inline:35px;
            color:$black-800;
            font-size:rem-calc(12);
            text-transform:uppercase;
            font-weight:400;

            vertical-align:top;
            &:not([class*="text-"]){
                text-align:center;
            }
            &:nth-of-type(odd){
                background-color:$grey-400;
            }
            &:nth-of-type(even){
                background-color:$grey-500;
            }
            &.col-gold{
                background-color:$gold-300;
            }
        }
    }
    tbody{
        td {
            padding-top:18px;
            &:not(.col-gold){
                padding-inline:35px;
            }
        }

        tr{
            &:nth-of-type(odd){
                td{
                    &:nth-of-type(odd){
                        background-color:$grey-100;
                    }
                    &:nth-of-type(even){
                        background-color:$grey-200;
                    }
                    &.col-gold{
                        background-color:$gold-100;
                    }
                }
            }
            &:nth-of-type(even){
                td{
                    &:nth-of-type(odd){
                        background-color:$grey-200;
                    }
                    &:nth-of-type(even){
                        background-color:$grey-300;
                    }
                    &.col-gold{
                        background-color:$gold-200;
                    }
                }
            }
            td{
                & .col-gold{
                    td{
                        &:nth-of-type(odd){
                            background-color:$grey-400;
                        }
                        &:nth-of-type(even){
                            background-color:$grey-500;
                        }
                        &.col-gold{
                            background-color:$gold-100;
                        }
                    }
                }
            }
            &.my-company{
                td{
                    color:white;
                    &:nth-of-type(odd){
                        background-color:#676462;
                    }
                    &:nth-of-type(even){
                        background-color:#646160;
                    }

                }
                .badge{
                    border:1px solid white;
                }
            }

        }
    }
}

.my-company{
    scroll-margin-top:100px;
}



.icon-fulfilled{
    &[data-fulfilled="1"]{
        color:$fulfilled;
        .not-fulfilled{
            display:none;
        }
    }
    &[data-fulfilled="0"]{
        color:$not-fulfilled;
        .fulfilled{
            display:none;
        }
    }
    &[data-negative="1"]{
        &[data-fulfilled="1"]{
         color:$not-fulfilled;
        }
        &[data-fulfilled="0"]{
            color:$fulfilled;
        }
    }
}

.inspect-icon{
    .bp-available{
        display:none;
    }
    &[data-has-bp]{
        .bp-available{
            display:block;
        }
    }
}

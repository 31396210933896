body {
    padding-right: 0 !important;
    header{
        padding-right:0 !important;
    }
}
h1, .h1{
    font-weight:900;
    font-family:$font-family-serif;
}

.pagination{
    display:flex;
    justify-content:center;
}

:is(h3,div,a)[id]{
    scroll-margin-top:150px;
}

.btn-rounded{
    padding:0 0 0 24px;
    border-radius:23px;
}

// Body
$body-bg: $white;
// Typography

$font-family-base: "Red Hat Text", sans-serif;
$font-family-sans-serif : $font-family-base;
$font-family-serif: "Red Hat Display", serif;

$primary: $gold;
$secondary: $grey-800;
$info: $blue-300;

$font-size-base: 1.125rem;

$h1-font-size: 64px;
$h2-font-size: 57px;
$h3-font-size: 32px;
$h4-font-size: 24px;
$h5-font-size: 20px;
$headings-font-weight:700;

$link-decoration:none;

$enable-dark-mode	: false;
$enable-rfs: false;
$enable-validation-icons: false;
$enable-deprecation-messages	: false;
$enable-important-utilities	: false;

$border-radius:rem-calc(15);
$btn-font-weight: 700;
$breadcrumb-bg:  $grey-500;
$breadcrumb-padding-y:              15px;
$breadcrumb-padding-x:              15px;
$breadcrumb-border-radius:          30px;

$theme-colors:(
"primary":    $primary,
"secondary":  $secondary,
"success":    $green,
"info":       $blue-300,
"grey":       $grey-400,
"warning":    $yellow,
"danger":     $rose,
"light":      $white,
"dark":       $black,
"red":        $red,
"gold":       $gold,
"silber":     $silver,
"bronze":     $bronze,
);

$badge-font-size: 14px;

$table-cell-padding-y:12px;
$table-cell-padding-x:12px;

$alert-border-width:  0;
$alert-padding-y: 30px;
$alert-padding-x:35px;
$alert-border-radius:30px;

$btn-border-radius: 15px;
$btn-border-radius-sm:10px;

$btn-line-height: 24px;

$input-btn-padding-y:12px;
$input-btn-padding-x: 1.25rem;

$form-select-padding-x:0.75rem;

$input-border-radius:15px;

$offcanvas-horizontal-width:50vw;
$offcanvas-padding-y:0;
$offcanvas-padding-x:rem-calc(50);
$offcanvas-title-line-height:em-calc(35,32);

$nav-link-color:$black-800;
$nav-link-padding-y:0.25rem;

$enable-validation-icons: true;

$breadcrumb-bg: $primary;

$pagination-color: $black-800;
